import "../../App.css";
import React from "react";

function About() {
  return (
    <>
      <div className="aboutContainer">
        <h1>Er sgu bare en konge</h1>
      </div>
    </>
  );
}

export default About;
