import "../../App.css";
import React from "react";

function Home() {
  return (
    <>
      <div className="homeContainer">
        <h1>Velkommen til Kjærs Korner DK</h1>
        <h3>Her kan du gå på opdagelse i kongens projekter</h3>
      </div>
    </>
  );
}

export default Home;
